<template>
  <div>
    <CRow v-if="bells != null">
      <CCol sm="12">
        <button
          type="button"
          class="btn-sm btn-info float-right"
          @click="mtd_back_to_table"
        >
          <i class="fas fa-arrow-left"></i>&nbsp;Regresar
        </button>
      </CCol>
      <br /><br />
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong> PROOVEDOR</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="2" md="2" sm="12" xs="12">
                <input type="hidden" v-model="header.acopio_id"/>
                <input type="hidden" v-model="header.supplier_id"/>
                <CInput
                  label="Documento del provedor"
                  placeholder="12345678"
                  v-model="header.document"
                  v-on:keyup.enter="mtd_search_supplier"
                />
              </CCol>
              <CCol lg="6" md="6" sm="12" xs="12">
                <CInput
                  label="Nombre del provedor"
                  placeholder="Nombre"
                  v-model="header.name"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="12" xs="12">
                <CInput
                  label="Base"
                  placeholder="Base"
                  v-model="header.base"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="12" xs="12">
                <CInput
                  label="Ruc del provedor"
                  placeholder="Ruc"
                  v-model="header.ruc"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="4" md="4" sm="12" xs="12">
                <label for="">Elegir serie</label>
                <v-select
                  :options="data_serie"
                  v-model="header.serie"
                  placeholder="Seleccione una serie"
                />
              </CCol>
              <CCol
                lg="6"
                md="6"
                sm="12"
                xs="12"
                v-if="header.ruc != 'NO TIENE'"
              >
                <label for="">Elegir Alias</label>
                <v-select
                  :options="data_alias"
                  v-model="header.alias"
                  placeholder="Seleccione un alias"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <strong> DETALLE DE ACOPIO</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol lg="2" md="2" sm="6" xs="12">
                <input type="hidden" v-model="item_detail.detail_acopio_id"/>
                <CInput
                  label="Ticket"
                  placeholder="Ticket"
                  v-model="item_detail.ticket"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Muestra(gr)"
                  placeholder="Muestra(gr)"
                  @blur="mtd_out_focus"
                  v-model="item_detail.muestra"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Cascara(%)"
                  placeholder="Cascara(%)"
                  @blur="mtd_out_focus"
                  v-model="item_detail.cascara"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Peso inicial"
                  placeholder="Peso inicial"
                  v-model="item_detail.peso_inicial"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Rendimiento Fisico(%)"
                  placeholder="Rendimiento Fisico(%)"
                  v-model="item_detail.rendimiento"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Humedad"
                  placeholder="Humedad"
                  v-model="item_detail.humedad"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Descuento x humedad"
                  placeholder="Descuento x humedad"
                  :value="cp_descuentoxhumedad"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Peso bruto"
                  placeholder="Peso bruto"
                  :value="cp_pesobruto"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Núm. sacos"
                  placeholder="Núm. sacos"
                  v-model="item_detail.sacos"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Tara saco"
                  placeholder="Tara saco"
                  :value="cp_tarasacos"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Taza"
                  placeholder="Taza"
                  v-model="item_detail.taza"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Peso neto"
                  placeholder="Peso neto"
                  :value="cp_pesoneto"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Neto num QQ"
                  placeholder="Neto num QQ"
                  :value="cp_netoqq"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Precio QQ"
                  placeholder="Precio QQ"
                  v-model="item_detail.precio_qq"
                  @blur="mtd_out_focus"
                  v-on:keypress="isNumber($event)"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Precio x KG"
                  placeholder="Precio x KG"
                  :value="cp_preciokg"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <CInput
                  label="Precio liq."
                  placeholder="Precio liq."
                  :value="cp_precioliq"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="4" md="4" sm="6" xs="12">
                <label for="">Elegir detalle</label>
                <v-select
                  :options="data_product_entry"
                  v-model="item_detail.product_entry_id"
                  placeholder="Seleccione un detalle"
                />
              </CCol>
              <CCol lg="12" md="12" sm="12" xs="12">
                <CTextarea
                  label="Observación"
                  placeholder="Observación"
                  v-model="item_detail.obs"
                />
              </CCol>
              <CCol class="text-center" lg="12" md="12" sm="12" xs="12" v-if="form.edit_form == 1">
                <CButton
                  size="lg"
                  color="danger"
                  v-c-tooltip.hover="{
                    content: 'Cancelar',
                  }"
                  @click="mtd_cancel_edit_acopio"
                >
                  <i class="fas fa-times"></i> Cancelar
                </CButton>
                &nbsp;&nbsp;
                <CButton
                  size="lg"
                  color="info"
                  v-c-tooltip.hover="{
                    content: 'Editar',
                  }"
                  @click="mtd_edit_acopio"
                >
                  <i class="fas fa-save"></i> Editar Acopio
                </CButton>
                <br><br>
              </CCol>
            </CRow>
            <CRow v-if="form.edit_form == 0 || form.edit_form == 2">
              <CCol lg="2" md="2" sm="6" xs="12">
                <label for="">&nbsp;</label>
                <br/>
                <CButton
                  label=""
                  color="success"
                  v-c-tooltip.hover="{
                    content: 'Verificar estimado',
                  }"
                  :disabled="cp_estimate"
                  @click="mtd_verif_neto"
                >
                  VERIFICAR ESTIMADO
                </CButton>
              </CCol>
              <CCol lg="4" md="4" sm="6" xs="12">
                <label for="">Elegir Certificado</label>
                <v-select
                  multiple
                  :options="data_certifications_filter"
                  v-model="item_detail.certification"
                  placeholder="Seleccione certificados(s)"
                />
              </CCol>
              <CCol
                lg="4"
                md="4"
                sm="6"
                xs="12"
                v-if="item_detail.verif_certificado == true"
              >
                <CInput
                  label="Certificado"
                  placeholder=""
                  value="ORGÁNICO"
                  :disabled="true"
                />
              </CCol>
              <CCol lg="2" md="2" sm="6" xs="12">
                <label for="">&nbsp;</label>
                <br />
                <CButton
                  label=""
                  color="info"
                  v-c-tooltip.hover="{
                    content: 'Agregar item',
                  }"
                  :disabled="cp_button"
                  @click="mtd_add_item"
                >
                  <i class="fa fa-plus"> </i> Agregar
                </CButton>
              </CCol>
            </CRow>
            <br />
            <CRow v-if="details.length > 0">
              <CCol lg="12" md="12" sm="12" xs="12">
                <table class="table table-bordered table-striped responsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Detalle</th>
                      <th>Peso neto</th>
                      <th>Liquidación</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in details" :key="index">
                      <td width="5%">{{ index + 1 }}</td>
                      <td width="30%">{{ item.product_entry.name }}</td>
                      <td width="15%">{{ item.peso_neto }}</td>
                      <td width="15%">{{ item.precio_liq }}</td>
                      <td width="10%">
                        <CButton
                          size="sm"
                          color="danger"
                          v-c-tooltip.hover="{
                            content: 'Eliminar',
                          }"
                          @click="mtd_delitemcarrito(index)"
                        >
                          <i class="fas fa-trash"></i>
                        </CButton>
                        &nbsp;&nbsp;
                        <CButton
                          size="sm"
                          color="success"
                          v-c-tooltip.hover="{
                            content: 'Editar',
                          }"
                          v-if="item.id>0"
                          @click="mtd_showdetail(item)"
                        >
                          <i class="fas fa-edit"></i>
                        </CButton>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2" class="text-right">
                        <strong>TOTALES:</strong>
                      </td>
                      <td>{{ cp_total_neto }}</td>
                      <td>{{ cp_total_liq }}</td>
                    </tr>
                  </tfoot>
                </table>
              </CCol>
            </CRow>
            <CCol class="text-center" lg="12" md="12" sm="12" xs="12">
              <!-- <CButton
                v-if="details.length > 0"
                size="lg"
                color="danger"
                v-c-tooltip.hover="{
                  content: 'Vaciar carrito',
                }"
                @click="mtd_vaciar_carrito"
              >
                <i class="fas fa-trash"></i> Vaciar carrito
              </CButton> -->
              &nbsp;&nbsp;
              <CButton
                size="lg"
                color="info"
                v-c-tooltip.hover="{
                  content: 'Registrar',
                }"
                v-if="form.edit_form == 2"
                @click="mtd_edit_acopio_array"
              >
                <i class="fas fa-save"></i> Registrar Detalle
              </CButton>
            </CCol>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol col="12">
        <CCard>
          <CCardHeader> CAMPAÑA NO INICIADA </CCardHeader>
          <CCardBody>
            COMUNIQUESE CON EL SUPERADMINISTRADOR PARA INICIAR UNA CAMPAÑA Y
            PODER CONTINUAR.
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { exit } from "process";

export default {
  name: "v-process-acopio",
  components: { vSelect },
  data() {
    return {
      prefix: "acopio",
      bells: null,
      data_alias: [],
      data_product_entry: [],
      data_serie: [
        { value: "0001", label: "0001" },
        { value: "0002", label: "0002" },
        { value: "0003", label: "0003" },
        { value: "0004", label: "0004" },
        { value: "0005", label: "0005" },
        { value: "0006", label: "0006" },
      ],
      form: {
        edit_form: 0
      },
      data_certifications: [],
      data_certifications_filter: [],
      header: {
        acopio_id: "",
        document: "",
        supplier_id: "",
        name: "",
        ruc: "NO TIENE",
        base: "",
        alias: [],
        serie: [],
      },
      details: [],
      item_detail: {
        acopio_id: "",
        detail_acopio_id: "",
        ticket: "",
        muestra: "",
        cascara: "",
        peso_inicial: "",
        dsctxh: "",
        peso_bruto: "",
        r: "",
        humedad: "",
        sacos: "",
        tara_sacos: "",
        taza: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_kg: "", // truncar 5
        precio_liq: "",
        product_entry_name: "",
        product_entry_id: [],
        verif_certificado: false,
        certifications: [],
        certification: [],
        obs: "",
      },
      verif_btn:false
    };
  },
  computed: {
    cp_descuentoxhumedad() {
      if (
        this.item_detail.humedad.length > 0 &&
        this.item_detail.peso_inicial.length > 0
      ) {
        let p1 = parseFloat(this.item_detail.humedad) - 12;
        let p2 = parseFloat(this.item_detail.peso_inicial) / 55.2;
        let res = p1 * 0.63 * p2;
        this.item_detail.dsctxh = parseFloat(res).toFixed(2);
        return parseFloat(res).toFixed(2);
      } else {
        this.item_detail.dsctxh = 0;
        return 0.0;
      }
    },
    cp_pesobruto() {
      if (
        parseFloat(this.item_detail.dsctxh) >= 0 &&
        this.item_detail.peso_inicial.length > 0
      ) {
        let peso = parseFloat(this.item_detail.peso_inicial);
        let dsctxh = parseFloat(this.item_detail.dsctxh);
        let res = parseFloat(peso - dsctxh).toFixed(2);
        this.item_detail.peso_bruto = res;
        return res;
      } else {
        this.item_detail.peso_bruto = 0;
        return 0.0;
      }
    },
    cp_tarasacos() {
      if (this.item_detail.sacos.length > 0) {
        let tar = parseFloat(this.item_detail.sacos) * 0.25;
        this.item_detail.tara_sacos = parseFloat(tar).toFixed(2);
        return this.item_detail.tara_sacos;
      } else {
        this.item_detail.tara_sacos = 0.0;
        return 0.0;
      }
    },
    cp_pesoneto() {
      if (
        this.item_detail.peso_bruto > 0 &&
        this.item_detail.tara_sacos > 0
        // && this.item_detail.dsctxh >= 0
      ) {
        let res = parseFloat(
          this.item_detail.peso_bruto - this.item_detail.tara_sacos
          // - this.item_detail.dsctxh
        );
        this.item_detail.peso_neto = res;
        return res;
      } else {
        return 0;
      }
    },
    cp_netoqq() {
      if (this.item_detail.peso_neto > 0) {
        let res = parseFloat(this.item_detail.peso_neto / 55.2).toString();
        let ar = res.split(".");
        let new_decimal = ar[1].substring(0, 2);
        let new_res = ar[0] + "." + new_decimal;
        this.item_detail.neto_num_qq = parseFloat(new_res);
        return parseFloat(new_res);
      } else {
        this.item_detail.neto_num_qq = 0;
        return 0;
      }
    },
    cp_precioliq() {
      if (
        this.item_detail.neto_num_qq > 0 &&
        this.item_detail.precio_qq.length > 0
      ) {
        let res = parseFloat(
          this.item_detail.neto_num_qq * this.item_detail.precio_qq
        ).toFixed(2);
        this.item_detail.precio_liq = res;
        return res;
      } else {
        this.item_detail.precio_liq = 0;
        return 0;
      }
    },
    cp_preciokg() {
      if (this.item_detail.precio_liq > 0 && this.item_detail.peso_neto > 0) {
        let res = parseFloat(
          this.item_detail.precio_liq / this.item_detail.peso_neto
        ).toString();
        let ar = res.split(".");
        let new_decimal = ar[1].substring(0, 7);
        let new_res = ar[0] + "." + new_decimal;
        this.item_detail.precio_kg = new_res;
        return new_res;
      } else {
        this.item_detail.precio_liq = 0;
        return 0;
      }
    },
    cp_estimate() {
      if (this.item_detail.product_entry_id != "") return false;
      return true;
    },
    cp_button() {
      if (this.item_detail.certification != "" &&
          this.item_detail.product_entry_id != "" &&
          this.verif_btn == true
        ) return false;
      return true;
    },
    cp_total_neto: function () {
      let tot = 0;
      if (this.details.length > 0) {
        this.details.forEach((element) => {
          tot += parseFloat(element.peso_neto,3);
        });
      } else {
        tot = 0;
      }
      return parseFloat(tot,2);
    },
    cp_total_liq: function () {
      let tot = 0;
      if (this.details.length > 0) {
        this.details.forEach((element) => {
          tot += parseFloat(element.precio_liq,3);
        });
      } else {
        tot = 0;
      }
      return parseFloat(tot,2);
    }
  },
  created() {
    this.mtd_show_idAcopio();
    this.mtd_getdata();
    this.mtd_show_data();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
        this.get({
            url: this.$store.getters.get__url + "/" + this.prefix,
            token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.bells = response.bells;
          this.data_alias = response.alias;
          this.data_product_entry = response.data_product_entry;
          this.data_certifications = response.data_certifications;
          this.data_certifications_filter = response.data_certifications;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_show_idAcopio: function() {
      this.get({
        url: this.$store.getters.get__url + 
        "/" + this.prefix +
        "/" + this.$route.params.id + "/show",
        token: this.$store.getters.get__token
      })
      .then((response) =>{
        // Acopio
        this.header.acopio_id = response.acopio_id;
        this.mtd_show_details();
      })
      .catch((errors)=>{
        // bus.$emit("alert", {
        //   color: "danger",
        //   message: errors,
        // });
      });
    },
    mtd_show_data: function() {
      let idAcopio_detail = this.$route.params.id;

      // Data to Show
      this.get({
        url: this.$store.getters.get__url + 
        "/" + this.prefix +
        "/" + idAcopio_detail + "/show",
        token: this.$store.getters.get__token
      })
      .then((response) =>{
        // Supplier
        this.header.supplier_id = response.acopio.supplier_id;
        this.header.document = response.acopio.proveedor.document;
        this.header.name = response.acopio.proveedor.name +" "+ response.acopio.proveedor.last_name;
        this.header.base = response.acopio.proveedor.base;
        this.header.ruc = response.acopio.proveedor.ruc;
        
        // Serie / Alias
        this.header.serie = response.acopio.serie;
        this.header.alias = [{value:response.acopio.alias_id, label:response.acopio.alias.last_name+" "+response.acopio.alias.name}];

        this.mtd_verif_neto();
        this.item_detail.push(response);
      })
      .catch((errors)=>{
        // bus.$emit("alert", {
        //   color: "danger",
        //   message: errors,
        // });
      });
    },
    mtd_show_details: function() {
      this.get({
        url: this.$store.getters.get__url + 
        "/" + this.prefix +
        "/" + this.header.acopio_id + "/show_details",
        token: this.$store.getters.get__token
      })
      .then((response) =>{
        response.forEach((element) => {
          this.details.push(element);
        });
      })
      .catch((errors)=>{
        // bus.$emit("alert", {
        //   color: "danger",
        //   message: errors,
        // });
      });
    },
    mtd_search_supplier: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.header.document +
          "/search_supplier",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response) {
            bus.$emit("alert", {
              color: "success",
              message: "DOCUMENTO ENCONTRADO",
            });
            this.header.supplier_id = response.id;
            this.header.name = response.last_name + " " + response.name;
            this.header.ruc = response.ruc == null ? "NO TIENE" : response.ruc;
            this.header.base = response.base;
            /** certification */
            // let cert = response.certification;
            // let ar = cert.split(",");
            // let temp = [];
            // for (let index = 1; index < ar.length; index++) {
            //   const id_cer = ar[index];
            //   this.data_certifications.forEach((element) => {
            //     if (element.value == id_cer) {
            //       temp.push(element);
            //     }
            //   });
            // }
            // this.data_certifications_filter = temp;
            // log
          } else {
            bus.$emit("alert", {
              color: "warning",
              message: "EL DOCUMENTO NO EXISTE",
            });
            this.header.document = "";
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_out_focus: function () {
      this.item_detail.muestra =
        this.item_detail.muestra > 0
          ? parseFloat(this.item_detail.muestra).toFixed(2)
          : 0;
      this.item_detail.cascara =
        this.item_detail.cascara > 0
          ? parseFloat(this.item_detail.cascara).toFixed(2)
          : 0;
      this.item_detail.peso_inicial =
        this.item_detail.peso_inicial > 0
          ? parseFloat(this.item_detail.peso_inicial).toFixed(2)
          : 0;
      this.item_detail.rendimiento =
        this.item_detail.rendimiento > 0
          ? parseFloat(this.item_detail.rendimiento).toFixed(2)
          : 0.0;
      this.item_detail.humedad =
        this.item_detail.humedad > 0
          ? parseFloat(this.item_detail.humedad).toFixed(2)
          : 0.0;
      this.item_detail.sacos =
        this.item_detail.sacos > 0
          ? parseFloat(this.item_detail.sacos).toFixed(2)
          : 0.0;
      this.item_detail.taza =
        this.item_detail.taza > 0
          ? parseFloat(this.item_detail.taza).toFixed(2)
          : 0.0;
      this.item_detail.precio_qq =
        this.item_detail.precio_qq > 0
          ? parseFloat(this.item_detail.precio_qq).toFixed(2)
          : 0.0;
    },
    mtd_verif_neto: function () {
      let sum_carrito = 0;
      this.details.forEach((element) => {
        sum_carrito += element.peso_neto;
      });
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/verif",
        token: this.$store.getters.get__token,
        params: {
          supplier_id: this.header.supplier_id,
          peso_neto: this.item_detail.peso_neto,
          bell_id: this.bells.id,
          sum_carrito: sum_carrito,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "PESO VERIFICADO CORRECTAMENTE",
            });
            this.item_detail.verif_certificado = true;
            this.verif_btn = true;
          } else {
            this.item_detail.verif_certificado = false;
            bus.$emit("alert", {
              color: "warning",
              message: "EL PESO EXCEDE AL ESTIMADO POR EL PROVEEDOR",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_add_item: function () {
      this.form.edit_form = 2;
      this.item_detail.product_entry = {value:this.item_detail.product_entry_id.value, name:this.item_detail.product_entry_id.label};
      this.item_detail.acopio_id = this.header.acopio_id;
      this.item_detail.id = null;
      this.details.push(this.item_detail);
      this.item_detail = {
        acopio_id: "",
        detail_acopio_id: "",
        ticket: "",
        muestra: "",
        cascara: "",
        peso_inicial: "",
        dsctxh: "",
        peso_bruto: "",
        r: "",
        humedad: "",
        sacos: "",
        tara_sacos: "",
        taza: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_kg: "", // truncar 5
        precio_liq: "",
        product_entry_name: "",
        product_entry_id: [],
        verif_certificado: false,
        certifications: [],
        certification: [],
        obs: "",
      };
    },
    mtd_delitemcarrito: function (pos) {
      let temp = [];
      this.details.forEach((element, index) => {
        if (index !== pos) {
          temp.push(element);
        }
      });
      this.details = temp;
    },
    mtd_showdetail: function(pos){
      this.form.edit_form = 1;

      this.get({
        url: this.$store.getters.get__url + 
        "/" + this.prefix +
        "/" + pos.id + "/show",
        token: this.$store.getters.get__token
      })
      .then((response) =>{
        //Detalle
        this.item_detail.detail_acopio_id = response.id;
        this.item_detail.bell_id  = response.acopio.bell_id;
        this.item_detail.acopio_id  = response.acopio_id;
        this.item_detail.ticket  = response.ticket;
        this.item_detail.muestra = response.muestra;
        this.item_detail.cascara = response.cascara;
        this.item_detail.peso_inicial = response.peso_inicial;
        this.item_detail.rendimiento  = response.rendimiento;
        this.item_detail.humedad = response.humedad;
        this.item_detail.sacos = response.num_saco;
        this.item_detail.taza = response.taza;
        this.item_detail.precio_qq = response.precio_qq;
        this.item_detail.product_entry_id = {value:response.product_entry.id, label:response.product_entry.name};
        this.item_detail.product_entry_ids = response.product_entry.name;
        this.item_detail.certifications = response.certification;
        this.item_detail.obs = response.obs;
      })
      .catch((errors) =>{
        // bus.$emit("alert", {
        //   color: "danger",
        //   message: errors,
        // });
      });
    },
    mtd_vaciar_carrito: function () {
      this.details = [];
    },
    mtd_cancel_edit_acopio: function(){
      this.form.edit_form = 0;
      this.item_detail = {
        acopio_id: "",
        detail_acopio_id: "",
        ticket: "",
        peso_inicial: "",
        dsctxh: "",
        peso_bruto: "",
        r: "",
        humedad: "",
        sacos: "",
        tara_sacos: "",
        taza: "",
        peso_neto: "", // truncar
        neto_num_qq: "", // truncar
        precio_qq: "",
        precio_kg: "", // truncar 5
        precio_liq: "",
        product_entry_id: [],
        certifications: [],
        certification: [],
        verif_certificado: false,
        obs: "",
      };
    },
    mtd_edit_acopio: function () {
      this.header.alias =  this.header.alias.value;
      this.header.serie =  this.header.serie.value;
      this.item_detail.product_entry_id =  this.item_detail.product_entry_id.value;

      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/edit",
        token: this.$store.getters.get__token,
        params: {
          header: this.header,
          details: this.item_detail,
          bell_id: this.bells.id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            this.header = {
              acopio_id: "",
              document: "",
              supplier_id: "",
              name: "",
              ruc: "NO TIENE",
              base: "",
              alias: null,
              serie: null
            };
            this.details = [];
            this.item_detail = {
              acopio_id: "",
              detail_acopio_id: "",
              ticket: "",
              peso_inicial: "",
              dsctxh: "",
              peso_bruto: "",
              r: "",
              humedad: "",
              sacos: "",
              tara_sacos: "",
              taza: "",
              peso_neto: "", // truncar
              neto_num_qq: "", // truncar
              precio_qq: "",
              precio_kg: "", // truncar 5
              precio_liq: "",
              product_entry_id: [],
              certifications: [],
              certification: [],
              verif_certificado: false,
              obs: "",
            };
            bus.$emit("alert", {
              color: "success",
              message: "ACOPIO EDITADO CORRECTAMENTE",
            });
            window.open("/assets/acopio/" + response.uuid + ".pdf", "_blank");
            this.mtd_back_to_table();
            this.form.edit_form = 0;
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.exception,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_edit_acopio_array: function(){
      this.item_detail.product_entry_id =  this.item_detail.product_entry_id.value;
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/editarray",
        token: this.$store.getters.get__token,
        params: {
          acopio_id: this.header.acopio_id,
          details: this.details,
          bell_id: this.bells.id,
        },
      })
      .then((response) => {
        if (response.state == 0) {
          this.header = {
            acopio_id: "",
            document: "",
            supplier_id: "",
            name: "",
            ruc: "NO TIENE",
            base: "",
            alias: null,
            serie: null
          };
          this.details = [];
          this.item_detail = {
            acopio_id: "",
            detail_acopio_id: "",
            ticket: "",
            peso_inicial: "",
            dsctxh: "",
            peso_bruto: "",
            r: "",
            humedad: "",
            sacos: "",
            tara_sacos: "",
            taza: "",
            peso_neto: "", // truncar
            neto_num_qq: "", // truncar
            precio_qq: "",
            precio_kg: "", // truncar 5
            precio_liq: "",
            product_entry_id: [],
            certifications: [],
            certification: [],
            verif_certificado: false,
            obs: "",
          };
          bus.$emit("alert", {
            color: "success",
            message: "ACOPIO EDITADO CORRECTAMENTE",
          });
          window.open("/assets/acopio/" + response.uuid + ".pdf", "_blank");
          this.mtd_back_to_table();
        } else {
          bus.$emit("alert", {
            color: "danger",
            message: response.exception,
          });
        }
      })
      .catch((errors) => {
        // bus.$emit("alert", {
        //   color: "danger",
        //   message: errors,
        // });
      });
    },
    mtd_back_to_table: function () {
      location.reload();
      this.$router.go(-1);
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>